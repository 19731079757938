function PartnerLogo(props) {
  return (
    <div className="partner-logo-container">
      <a href={props.link} rel="noreferrer" target="_blank">
        <img src={props.logoSrc} alt={props.logoAlt} className="partner-logo" />
      </a>
    </div>
  );
}

export default PartnerLogo;
