import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";
import logo from "../../assets/LineFooter.svg";

function Line() {
  return (
    <div className="line-container">
      <Link to={{ pathname: "/" }}>
        <ReactSVG src={logo} />
      </Link>
    </div>
  );
}

export default Line;
