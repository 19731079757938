import { ReactSVG } from "react-svg";
import logo from "../../assets/BTL-Logo-2025.svg";

function BigLogo() {
  return (
    <div className="logo-container big-logo">
      <ReactSVG src={logo} />
    </div>
  );
}

export default BigLogo;
