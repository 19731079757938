export const sanitize = (text) => {
  return text
    .replace(/#br#/gi, "<br />")
    .replace(/\+title\+/gi, "")
    .replace(/\+title-text\+/gi, "")
    .replace(/\+team-member-title\+/gi, "")
    .replace(/\+team-member-text\+/gi, "")
    .replace(/\+sponsor-badge-title\+/gi, "")
    .replace(/\+sponsor-badge-text\+/gi, "")
    .replace(/\+sponsor-badge-list\+/gi, "");
};
