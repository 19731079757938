import { ReactSVG } from "react-svg";
import map from "../../assets/MapBig.svg";
import infoLine from "../../assets/InfoLine.svg";
import arrowLocationD from "../../assets/icon_location_zinnoberrot_d.svg";
import arrowPoiD from "../../assets/icon_poi_zinnoberrot_d.svg";
import cross from "../../assets/icon_cross_aubergine.svg";
import close from "../../assets/icon_close_aubergine.svg";
import { useCallback, useState } from "react";
import arrowRight from "../../assets/icon_arrow4_zinnoberrot.svg";
import arrowUp from "../../assets/icon_arrow_zinnoberrot.svg";
import Slider from "react-touch-drag-slider";

const routeConfig = {
  etappe1: {
    stopps: [
      {
        key: "britische-panzer-marktplatz-wismar",
        position: {
          x: 306,
          y: 149,
        },
        type: "historical",
        name: "Marktplatz Wismar",
        images: [
          {
            url: "https://res.cloudinary.com/dvyf1gffu/image/upload/v1708602725/Bildschirmfoto_2024_02_22_um_12_51_53_4a0ac14b94.png",
            title:
              "Zerstörter Ostflügel des Rathauses mit britischen Panzern im Vordergrund.",
          },
          {
            url: "https://res.cloudinary.com/dvyf1gffu/image/upload/v1727209478/General_Barber_und_General_Bols_in_Wismar_7070aabb5b.jpg",
            title:
              "Dies Sieger in den Straßen von Wismar, hier der Schotte Generalmajor Barber (2.03 m) im SchottenrockRock",
          },
        ],
        text: "Britische Panzer stehen auf dem Marktplatz von Wismar im Mai 1945. Im Hintergrund ist der durch einen Bombentreffer zerstörte Ostflügel des Rathauses der Seestadt zu sehen.",
        poiKey: 104,
      },
      {
        key: "treffen-montgomery-rokossowsky-stadtvilla-wismar",
        position: {
          x: 296,
          y: 174,
        },
        type: "historical",
        name: "Stadtvilla Wismar",
        images: [
          {
            url: "https://res.cloudinary.com/dvyf1gffu/image/upload/v1726776159/IMG_9844_77b0d8f5fd.jpg",
            title:
              "Begleitet werden die Heerführer auch von Kriegsberichterstatter aus Amerika, England und der Sowjetunion",
          },
          {
            url: "https://res.cloudinary.com/dvyf1gffu/image/upload/v1708602500/Bildschirmfoto_2024_02_22_um_12_48_01_f95e3a35a2.png",
            title: "Die zwei Marschälle im Gespräch.",
          },
          {
            url: "https://res.cloudinary.com/dvyf1gffu/image/upload/v1708602189/Bildschirmfoto_2024_02_22_um_12_42_47_5bf0600ae5.png",
            title: "Gruppenbild der Militärdelegationen beider Alliierter",
          },
        ],
        text: "Am 7. Mai 1945 treffen sich der britische Marschall Bernard Law Montgomery und der sowjetische Marschall Konstantinowitsch Rokossowski in der Stadtvilla Dr.-Unruh-Str. 7 in Wismar. Sie sind die Anführer der in Norddeutschland operierenden Alliierten. In Wismar endet damit der Zweite Weltkrieg.",
        poiKey: 106,
      },
      {
        key: "denkort-klein-trebbow",
        position: {
          x: 175,
          y: 364,
          isRight: true,
        },
        type: "historical",
        name: "Denkort Klein Trebbow",
        images: [
          {
            url: "https://res.cloudinary.com/dvyf1gffu/image/upload/v1727281014/20240515_191329_fce04e36b4.jpg",
            title: "",
          },
          {
            url: "https://res.cloudinary.com/dvyf1gffu/image/upload/v1727280858/20240515_191358_fda4dd62e5.jpg",
            title: "",
          },
        ],
        text: "Im 'abhörsicheren' Teehaus von Klein Trebbow treffen sich 1944 Mitglieder des Widerstands gegen Adolf Hitler. Klaus Graf Schenk von Stauffenberg und Fritz-Dietlof von der Schulenburg waren befreundet und vertraut – beide ließen ihr Leben für ein freies Deutschland nachdem das Attentat vom 20. Juli 1944 scheiterte.",
        poiKey: 110,
      },
      {
        key: "gedenkplatte-marianne-grunthal",
        position: {
          x: 197,
          y: 522,
        },
        type: "historical",
        name: "Gedenkplatte Marianne Grunthal",
        images: [
          {
            url: "https://res.cloudinary.com/dvyf1gffu/image/upload/v1708683549/01b_Bahnhof_Schwerin_2c1ab7e73b.jpg",
            title: "Gedenktafel für Marianne Grunthal",
          },
        ],
        text: "10 Minuten vor Eintreffen der amerikanischen Befreier wird hier die Lehrerin Marianne Grunthal am Laternenmast vor dem Bahnhof von versprengten SS Einheiten gehängt. Sie sprach aus was Tatsache war - der Krieg ist vorbei.",
        poiKey: 112,
      },
    ],
    pois: [
      {
        key: "sowjetischer-ehrenfriedhof-wismar",
        position: {
          x: 245,
          y: 133,
          isRight: true,
        },
        type: "historical",
        name: "Sowjetischer Ehrenfriedhof",
        images: [
          {
            url: "https://res.cloudinary.com/dvyf1gffu/image/upload/v1708682923/2634_4582_1_g_ec54fe8c14.jpg",
            title: "Sowjetischer Soldatenfriedhof",
          },
        ],
        text: "Auf dem Ehrenfriedhof wurden 348 Soldaten der Roten Armee beerdigt, die im Raum Wismar in den letzten Kriegstagen gefallenen sind.",
        poiKey: 102,
      },
      {
        key: "villa-montgomery",
        position: {
          x: 245,
          y: 153,
          isRight: true,
        },
        type: "touristic",
        name: 'Villa "Montgomery"',
        images: [
          {
            url: "https://res.cloudinary.com/dvyf1gffu/image/upload/v1708548735/Bildschirmfoto_2024_02_21_um_21_51_36_828ae45725.png",
            title: "Frontansicht der Villa von der Straßenseite",
          },
        ],
        text: "In der 1924 erbauten Fabrikantenvilla treffen im Mai 1945 die Marschälle Bernard Montgomery und Konstanin Rokosowsky aufeinander.",
        poiKey: 103,
      },
      {
        key: "georgenkirche",
        position: {
          x: 245,
          y: 173,
          isRight: true,
        },
        type: "touristic",
        name: "Kirchen im Gotischen Viertel",
        images: [
          {
            url: "https://res.cloudinary.com/dvyf1gffu/image/upload/v1708681653/2634_5592_1_g_95b9be53e6.jpg",
            title: "Die Georgenkirche nach dem Wiederaufbau.",
          },
          {
            url: "https://res.cloudinary.com/dvyf1gffu/image/upload/v1727020821/Marienkireche_und_gotisches_Viertel_19df8c0440.jpg",
            title: "Bronzemodell des Marienplatz vor und nach der Zerstörung.",
          },
        ],
        text: "Beim letzten Luftangriff auf Wismar wurde das gotische Viertel schwer von Fliegerbomben der Royal Air Force getroffen. Neben 14 Menschen wurden viele historische Gebäude zerstört oder schwer beschädigt. Noch heute sind die Narben im ehemals einzigartigen gotischen Viertel sichtbar und eine Mahnung für Frieden in der Welt. Nach 1990 konnte die als Ruine erhaltene Georgenkirche wieder aufgebaut werden, die Marienkirche hingegen überstand den Luftangriff und die Sprengung des Kirchenschiffes durch das DDR-Regime leider nicht.",
        poiKey: 105,
      },
      {
        key: "schlagbaeume-ost-west",
        position: {
          x: 331,
          y: 128,
        },
        type: "historical",
        name: "Schlagbäume bei Kritzow",
        images: [
          {
            url: "https://res.cloudinary.com/dvyf1gffu/image/upload/v1727208622/wobau_2010_1_2e261595d4.jpg",
            title: "Schlagbaum in Kritzow Burg in Wismar",
          },
          {
            url: "https://res.cloudinary.com/dvyf1gffu/image/upload/v1727026884/04_08_Schlagbaum_Westmecklenburg_6e614b3e51.jpg",
            title: "Schlagbaum 1945 in Westmecklenburg",
          },
        ],
        text: "Einen solchen Schlagbaum gab es 1945 auch in Kritzow bei Wismar. Besetzt waren diese 'Grenzposten' mit Soldaten der westlichen Alliierten und den östlich liegenden sowjetischen Truppen.",
        poiKey: 107,
      },
      {
        key: "treffen-montgomery-rokossowsky-schloss-hasenwinkel",
        position: {
          x: 423,
          y: 256,
        },
        type: "historical",
        name: "Marschalltreffen auf Schloss Hasenwinkel",
        images: [
          {
            url: "https://res.cloudinary.com/dvyf1gffu/image/upload/v1708605091/Bildschirmfoto_2024_02_22_um_13_31_11_7bf650bdd1.png",
            title:
              "Die Kuban Kussaken parodieren vor Montgomery und Rokossowski",
          },
        ],
        text: "Unter Ausschluss der Öffentlichkeit treffen sich am 11./12. Mai 1945 der britische Marschall Montgomery der sowjetische Marschall Rokossowsky erneut. Diesmal lädt Rokossowsky ins Schloss Hasenwinkel ein. Einen tiefen Eindruck auf seinen Gast machte die Siegesparade der Kuban-Kosaken.",
        poiKey: 108,
      },
      {
        key: "weltkriegsbunker",
        position: {
          x: 309,
          y: 204,
        },
        type: "touristic",
        name: "Weltkriegsbunker",
        images: [
          {
            url: "https://res.cloudinary.com/dvyf1gffu/image/upload/v1727026028/Bunker_Wismar_POI_2_7e944a3bc7.jpg",
            title: "",
          },
          {
            url: "https://res.cloudinary.com/dvyf1gffu/image/upload/v1708682659/hwi_luftbeobachtungsbunker_triwalk_14_2424726c0a.webp",
            title: "Beobachtungsbunker",
          },
        ],
        text: "Beobachtungsbunker als Überbleibsel der Luftverteidigung der Hansestadt Wismar während des Zweiten Weltkriegs. Von hier lässt sich die im Kessel liegende Seestadt Wismar sehr gut einsehen.",
        poiKey: 109,
      },
      {
        key: "krankenmorde-euthanasie",
        position: {
          x: 137,
          y: 513,
          isRight: true,
        },
        type: "touristic",
        name: "Krankenmorde",
        images: [
          {
            url: "https://res.cloudinary.com/dvyf1gffu/image/upload/v1708683809/Bildschirmfoto_2024_02_23_um_11_23_05_f75b2ce396.png",
            title: "Denkmal auf dem Gelände des Helios-Klinikums",
          },
        ],
        text: "In der Klinik auf dem Sachsenberg in Schwerin werden während der NS-Herrschaft 1933-45 systematisch Menschen die man nicht für lebenswert hält misshandelt, verschleppt und getötet.",
        poiKey: 111,
      },
      {
        key: "sowjetischer-ehrenfriedhof-grabanlagen",
        position: {
          x: 137,
          y: 533,
          isRight: true,
        },
        type: "touristic",
        name: "Sowj. Ehrenfriedhof",
        images: [
          {
            url: "https://res.cloudinary.com/dvyf1gffu/image/upload/v1708684071/Bildschirmfoto_2024_02_23_um_11_27_40_ede4f6b706.png",
            title: "",
          },
          {
            url: "https://res.cloudinary.com/dvyf1gffu/image/upload/v1708684136/Bildschirmfoto_2024_02_23_um_11_28_38_b01e019c4a.png",
            title: "",
          },
        ],
        text: "Die Besonderheit dieses Ehrenfriedhofs gegenüber anderen besteht darin, dass hier heute die unterschiedlichsten Opfergruppen begraben liegen: KZ-Häftlinge, sowjetische zivile Bürger, Angehörige der Roten Armee, nach 1945 verstorbene Verfolgte des Naziregimes und deren Ehepartner, umgebettete verdiente Sozialisten und das Grab von Kurt Bürger. ",
        poiKey: 113,
      },
    ],
    start: {
      flag: {
        position: {
          x: 261,
          y: 138,
        },
      },
      name: {
        position: {
          x: -114,
          y: -54,
        },
        text: "Wismar",
      },
      poi: {
        key: "kinder-uni-hochschule-wismar",
        position: {
          x: 2,
          y: -44,
        },
        type: "touristic",
        name: "Kinder Uni - HS Wismar",
        images: [
          {
            url: "https://res.cloudinary.com/dvyf1gffu/image/upload/v1708624869/IMG_7606_6ecbb2b7e3.jpg",
            title: "Vorlesung an der Kinder Uni der Hochschule Wismar.",
          },
          {
            url: "https://res.cloudinary.com/dvyf1gffu/image/upload/v1708624869/IMG_7601_fb4e80efc8.jpg",
            title: "Schüler während einer Vorlesung der Kinder Uni in Wismar.",
          },
        ],
        text: "Die KinderUni Wismar feiert 2025 ihr 20-jähriges Bestehen. Sie funktioniert wie eine richtige Hochschule mit Vorlesungen, wobei das Publikum aus Kinderstudierenden im Altern zwischen 10 und 12 Jahren besteht. Das Team von `Bike the Line` startet am 27.06.2025 zusammen mit Kinderstudierenden in die erste Etappe zu den Vereinten Nationen nach Wien.",
        poiKey: 101,
      },
    },
    end: {
      flag: {
        position: {
          x: 152,
          y: 510,
        },
      },
      name: {
        position: {
          x: -125,
          y: 42,
        },
        text: "Schwerin",
      },
      poi: {
        key: "hauptbahnhof-schwerin",
        position: {
          x: 12,
          y: 51,
        },
        type: "touristic",
        name: "Hauptbahnhof Schwerin",
        images: [
          {
            url: "https://res.cloudinary.com/dvyf1gffu/image/upload/v1732893217/Schwerin_Bahnhof_800px_b96646e469.jpg",
            title: "",
          },
        ],
        text: "Das Ende unserer ersten Etappe wird der Hauptbahnhof Schwerin sein.",
        poiKey: 114,
      },
    },
  },
};

function Route() {
  const [isShowAllPois, setIsShowAllPois] = useState(false);
  const pois = []
    .concat(routeConfig.etappe1.start.poi)
    .concat(routeConfig.etappe1.end.poi)
    .concat(routeConfig.etappe1.stopps)
    .concat(routeConfig.etappe1.pois);

  pois.sort((itemA, itemB) => itemA.poiKey - itemB.poiKey);
  const currentSlideState = {};
  pois.forEach((poi) => {
    currentSlideState[poi.poiKey] = 0;
  });

  const [slideState, setSlideState] = useState(currentSlideState);

  const setCurrentSlide = useCallback(
    (poi, idx) => {
      const currentSlideState = {};
      Object.assign(currentSlideState, slideState);
      currentSlideState[poi] = idx;

      setSlideState(currentSlideState);
    },
    [slideState]
  );

  const toggleShowAllPois = useCallback(() => {
    setIsShowAllPois(!isShowAllPois);
  }, [isShowAllPois]);

  const [isShowInfo, setIsShowInfo] = useState(false);

  const toggleShowInfo = useCallback(
    (state, scrollPos) => {
      if (typeof state === "undefined") {
        setIsShowInfo(!isShowInfo);
      } else {
        setIsShowInfo(state);
      }

      if (scrollPos) {
        const target = document.getElementById(`poi-${scrollPos}`);

        if (target) {
          const container = target.parentElement;
          if (container) {
            container.scrollTo({
              top: target.offsetTop - container.offsetTop,
              behavior: "smooth",
            });
          }
        }
      }
    },
    [isShowInfo]
  );

  return (
    <div className="tour-route-container">
      <div className="options-container" onClick={toggleShowAllPois}>
        <div className={`options-checkbox${isShowAllPois ? " active" : ""}`}>
          <div className="icon">
            <ReactSVG src={cross} />
          </div>
        </div>
        <div className={`options-label`}>Zeige alle POIs</div>
      </div>
      <div className={`info-container${isShowInfo ? " visible" : ""}`}>
        <div className={`info-etappe-name`}>
          <div className="text">Etappe 1</div>
          <div className="close-icon" onClick={() => toggleShowInfo(false)}>
            <ReactSVG src={close} />
          </div>
        </div>
        <div className={`info-etappe-title`}>Wismar - Schwerin</div>
        <div className={`info-etappe-details`}>
          <div className="content-left-info-left">
            <ReactSVG src={arrowRight} />
            <span>48 km</span>
          </div>
          <div className="content-left-info-right">
            <ReactSVG src={arrowUp} />
            <span>139 hm</span>
          </div>
        </div>
        <div className="poi-container">
          <div className={`info-etappe-line`}>
            <ReactSVG src={infoLine} />
          </div>
          {pois.map((poi) => (
            <div className="poi-item" id={`poi-${poi.poiKey}`}>
              <div className={`info-etappe-image-slider`}>
                <Slider
                  onSlideComplete={(i) => {
                    setCurrentSlide(poi.poiKey, i);
                  }}
                  activeIndex={slideState[poi.poiKey]}
                  threshHold={100}
                  transition={0.25}
                >
                  {poi.images.map(({ url, title }, index) => (
                    <img src={url} key={index} alt={title} />
                  ))}
                </Slider>
                {poi.images[slideState[poi.poiKey]]?.title && (
                  <div className="caption-text">
                    {poi.images[slideState[poi.poiKey]].title}
                  </div>
                )}
              </div>
              <div className="info-etappe-image-slider-indicator">
                <div className="indicator-container">
                  <div className="dots">
                    {poi.images.map((img, idx) => (
                      <div
                        key={idx}
                        onClick={() => setCurrentSlide(poi.poiKey, idx)}
                        className={`dot${
                          slideState[poi.poiKey] === idx ? " active" : ""
                        }`}
                      />
                    ))}
                  </div>
                </div>
              </div>
              <div className={`info-etappe-poi-title`}>
                <div className="icon">
                  <ReactSVG src={arrowLocationD} />
                </div>
                <div className="text">{poi.name}</div>
              </div>
              <div className={`info-etappe-poi-text`}>{poi.text}</div>
            </div>
          ))}
        </div>
      </div>
      <div className="map-big">
        <ReactSVG src={map} />
        <div className="etappe-1">
          <div
            className="start"
            style={{
              left: `${routeConfig.etappe1.start.flag.position.x}px`,
              top: `${routeConfig.etappe1.start.flag.position.y}px`,
            }}
            onClick={() => toggleShowInfo(true, 101)}
          >
            <div className="icon">
              <ReactSVG src={arrowLocationD} />
            </div>
            <div
              className="name"
              style={{
                left: `${routeConfig.etappe1.start.name.position.x}px`,
                top: `${routeConfig.etappe1.start.name.position.y}px`,
              }}
            >
              {routeConfig.etappe1.start.name.text}
            </div>
            <div
              className="poi"
              style={{
                left: `${routeConfig.etappe1.start.poi.position.x}px`,
                top: `${routeConfig.etappe1.start.poi.position.y}px`,
              }}
            >
              <div className="poi-icon" />
              <div className="poi-name">
                Start | {routeConfig.etappe1.start.poi.name}
              </div>
            </div>
          </div>
          <div
            className="end"
            style={{
              left: `${routeConfig.etappe1.end.flag.position.x}px`,
              top: `${routeConfig.etappe1.end.flag.position.y}px`,
            }}
            onClick={() => toggleShowInfo(true, 114)}
          >
            <div className="icon">
              <ReactSVG src={arrowLocationD} />
            </div>
            <div
              className="name"
              style={{
                left: `${routeConfig.etappe1.end.name.position.x}px`,
                top: `${routeConfig.etappe1.end.name.position.y}px`,
              }}
            >
              {routeConfig.etappe1.end.name.text}
            </div>
            <div
              className="poi"
              style={{
                left: `${routeConfig.etappe1.end.poi.position.x}px`,
                top: `${routeConfig.etappe1.end.poi.position.y}px`,
              }}
            >
              <div className="poi-icon" />
              <div className="poi-name">
                Ende | {routeConfig.etappe1.end.poi.name}
              </div>
            </div>
          </div>
          {routeConfig.etappe1.stopps.map((stop) => (
            <div
              key={stop.name}
              className="stop"
              style={{
                left: `${stop.position.x}px`,
                top: `${stop.position.y}px`,
              }}
              onClick={() => toggleShowInfo(true, stop.poiKey)}
            >
              <div className="icon">
                <ReactSVG src={arrowPoiD} />
              </div>
              <div
                className={`name${stop.position.isRight ? " is-right" : ""}`}
              >
                {stop.name}
              </div>
            </div>
          ))}
          {routeConfig.etappe1.pois.map((poi) => (
            <div
              key={poi.name}
              className={`pois${!isShowAllPois ? " hide" : ""}`}
              style={{
                left: `${poi.position.x}px`,
                top: `${poi.position.y}px`,
              }}
              onClick={() => toggleShowInfo(true, poi.poiKey)}
            >
              <div className="icon" />
              <div className={`name${poi.position.isRight ? " is-right" : ""}`}>
                {poi.name}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Route;
