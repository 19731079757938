import { Link } from "react-router-dom";
import BigLogo from "./BigLogo";
import Line from "./Line";
import { useCallback, useEffect, useState } from "react";

function Footer() {
  const [dsError, setDsError] = useState(false);

  const checkDS = useCallback((e) => {
    const dsField = document.getElementById("OPT_IN");
    if (!dsField.checked) {
      setDsError(true);
      e.preventDefault();
      e.stopPropagation();
    } else {
      setDsError(false);
    }
  }, []);

  useEffect(() => {
    if (window?.initalize && !window.isInitialized) {
      window.initalize();

      const scriptTag = document.createElement("script");
      scriptTag.src = "https://www.google.com/recaptcha/api.js?hl=de";
      scriptTag.setAttribute(
        "nonce",
        document
          .querySelector('meta[name="csp-nonce"]')
          ?.getAttribute("content")
      );
      scriptTag.addEventListener("load", () => {
        window.isInitialized = true;
      });
      document.body.appendChild(scriptTag);
    }
  }, []);

  return (
    <div className="footer-container">
      <div className="upper-circle">
        <div className="contact-content">
          <BigLogo />
          <Line />
          <div className="form-content">
            <div className="form-title">
              <h3>NEWSLETTER</h3>
            </div>
            <div className="form-container">
              <div
                className="sib-form"
                style={{
                  textAlign: "center",
                  marginTop: "8px",
                }}
              >
                <div id="sib-form-container" className="sib-form-container">
                  <div
                    id="error-message"
                    className="sib-form-message-panel"
                    style={{
                      margin: "0",
                      padding: "0",
                      fontSize: "16px",
                      textAlign: "left",
                      color: "#661d1d",
                      backgroundColor: "#ffeded",
                      borderColor: "#ff4949",
                      maxWidth: "540px",
                    }}
                  >
                    <div className="sib-form-message-panel__text sib-form-message-panel__text--center">
                      <svg
                        viewBox="0 0 512 512"
                        className="sib-icon sib-notification__icon"
                      >
                        <path d="M256 40c118.621 0 216 96.075 216 216 0 119.291-96.61 216-216 216-119.244 0-216-96.562-216-216 0-119.203 96.602-216 216-216m0-32C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm-11.49 120h22.979c6.823 0 12.274 5.682 11.99 12.5l-7 168c-.268 6.428-5.556 11.5-11.99 11.5h-8.979c-6.433 0-11.722-5.073-11.99-11.5l-7-168c-.283-6.818 5.167-12.5 11.99-12.5zM256 340c-15.464 0-28 12.536-28 28s12.536 28 28 28 28-12.536 28-28-12.536-28-28-28z" />
                      </svg>
                      <span
                        className="sib-form-message-panel__inner-text"
                        style={{ lineHeight: "14px" }}
                      >
                        Deine Anmeldung konnte nicht gespeichert werden. Bitte
                        versuche es erneut.
                      </span>
                    </div>
                  </div>
                  <div></div>
                  <div
                    id="success-message"
                    className="sib-form-message-panel"
                    style={{
                      fontSize: "16px",
                      textAlign: "left",
                      color: "#085229",
                      backgroundColor: "#e7faf0",
                      borderRadius: "3px",
                      borderColor: "#13ce66",
                      maxWidth: "540px",
                    }}
                  >
                    <div className="sib-form-message-panel__text sib-form-message-panel__text--center">
                      <svg
                        viewBox="0 0 512 512"
                        className="sib-icon sib-notification__icon"
                      >
                        <path d="M256 8C119.033 8 8 119.033 8 256s111.033 248 248 248 248-111.033 248-248S392.967 8 256 8zm0 464c-118.664 0-216-96.055-216-216 0-118.663 96.055-216 216-216 118.664 0 216 96.055 216 216 0 118.663-96.055 216-216 216zm141.63-274.961L217.15 376.071c-4.705 4.667-12.303 4.637-16.97-.068l-85.878-86.572c-4.667-4.705-4.637-12.303.068-16.97l8.52-8.451c4.705-4.667 12.303-4.637 16.97.068l68.976 69.533 163.441-162.13c4.705-4.667 12.303-4.637 16.97.068l8.451 8.52c4.668 4.705 4.637 12.303-.068 16.97z" />
                      </svg>
                      <span className="sib-form-message-panel__inner-text">
                        Deine Anmeldung war erfolgreich.
                      </span>
                    </div>
                  </div>
                  <div></div>
                  <div
                    id="sib-container"
                    className="sib-container--large sib-container--vertical"
                    style={{
                      textAlign: "center",
                      backgroundColor: "rgba(255,255,255,1)",
                      maxWidth: "540px",
                      borderWidth: "0px",
                      borderColor: "#C0CCD9",
                      borderStyle: "solid",
                      direction: "ltr",
                    }}
                  >
                    <form
                      id="sib-form"
                      method="POST"
                      action="https://29cbec4e.sibforms.com/serve/MUIFALci3FLO6gT3fhQNMvNZXSjp9BM0Ha2GMBRsnjmgIKJ1i6J5BjhscOdfsBS6VfT_dL8aOI7eTz_s2SI0nGZ85roMHV_9wwKxJFhjMEDNJ2tcA5xeSE57J514kYNzl-0T1L7orcPDEDmEHcoCp3-zslqo79chPtartNDjtBAYhLxKg2YsXtJqg2pRSV0H8SnnvjmL-FuVrAWG"
                      data-type="subscription"
                    >
                      <div style={{ padding: "8px 0" }}>
                        <div
                          className="sib-form-block"
                          style={{
                            fontSize: "16px",
                            textAlign: "left",
                            color: "#3C4858",
                            backgroundColor: "transparent",
                          }}
                        >
                          <div className="sib-text-form-block">
                            <p>
                              Melde Dich zu unserem Newsletter an, um nichts zu
                              verpassen.
                            </p>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="sib-input sib-form-block">
                          <div className="form__entry entry_block">
                            <div className="form__label-row ">
                              <label
                                className="entry__label"
                                style={{
                                  margin: "0",
                                  fontWeight: "700",
                                  textAlign: "left",
                                  fontSize: "16px",
                                  color: "#3c4858",
                                }}
                                htmlFor="EMAIL"
                                data-required="*"
                              >
                                Gib Deine E-Mail-Adresse ein, um Dich anzumelden
                              </label>

                              <div className="entry__field">
                                <input
                                  className="input "
                                  type="text"
                                  id="EMAIL"
                                  name="EMAIL"
                                  autoComplete="off"
                                  placeholder="E-Mail z.B. abc@xyz.com"
                                  data-required="true"
                                  required
                                  style={{
                                    color: "#000000",
                                  }}
                                />
                              </div>
                            </div>

                            <label
                              className="entry__error entry__error--primary entry__error--secondary"
                              style={{
                                fontSize: "16px",
                                lineHeight: "20px",
                                textAlign: "left",
                                color: "#661d1d",
                                backgroundColor: "#ffeded",
                                borderColor: "#ff4949",
                              }}
                            ></label>
                            <label
                              className="entry__error entry__error--primary"
                              style={{
                                display: dsError ? "initial" : "none",
                                fontSize: "16px",
                                lineHeight: "20px",
                                textAlign: "left",
                                color: "#661d1d",
                                backgroundColor: "#ffeded",
                                borderColor: "#ff4949",
                              }}
                            >
                              Bitte nimm die Datenschutzerklärung an.
                            </label>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="sib-optin sib-form-block">
                          <div className="form__entry entry_mcq">
                            <div className="form__label-row ">
                              <div className="entry__choice" style={{}}>
                                <label>
                                  <input
                                    type="checkbox"
                                    className="input_replaced"
                                    value="1"
                                    onChange={() => {}}
                                    id="OPT_IN"
                                    name="OPT_IN"
                                    required
                                    data-required="true"
                                  />
                                  <span
                                    className="checkbox checkbox_tick_positive"
                                    style={{ marginLeft: 0 }}
                                  ></span>
                                  <span
                                    style={{
                                      fontSize: "14px",
                                      textAlign: "left",
                                      color: "#3C4858",
                                      backgroundColor: "transparent",
                                    }}
                                  >
                                    <p>
                                      Ich akzeptiere die Datenschutzerklärung.
                                    </p>
                                    <span
                                      data-required="*"
                                      style={{ display: "inline" }}
                                      className="entry__label entry__label_optin"
                                    ></span>
                                  </span>
                                </label>
                              </div>
                            </div>
                            <label
                              className="entry__error entry__error--primary"
                              style={{
                                fontSize: "16px",
                                textAlign: "left",
                                color: "#661d1d",
                                backgroundColor: "#ffeded",
                                borderColor: "#ff4949",
                              }}
                            ></label>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="sib-captcha sib-form-block">
                          <div className="form__entry entry_block">
                            <div className="form__label-row ">
                              <div
                                className="g-recaptcha sib-visible-recaptcha"
                                id="sib-captcha"
                                data-sitekey="6LdaQDgqAAAAAJJkSDuMKWxhmfBrbQDCijOpQEGi"
                                data-callback="handleCaptchaResponse"
                                style={{ direction: "ltr" }}
                              ></div>
                            </div>
                            <label
                              className="entry__error entry__error--primary"
                              style={{
                                fontSize: "16px",
                                textAlign: "left",
                                color: "#661d1d",
                                backgroundColor: "#ffeded",
                                borderColor: "#ff4949",
                              }}
                            ></label>
                          </div>
                        </div>
                      </div>
                      <div style={{ padding: "8px 0" }}>
                        <div
                          className="sib-form-block"
                          style={{ textAlign: "right" }}
                        >
                          <button
                            className="sib-form-block__button sib-form-block__button-with-loader"
                            onClick={(e) => checkDS(e)}
                            style={{
                              fontSize: "14px",
                              textAlign: "right",
                              fontWeight: "700",
                              color: "#FFFFFF",
                              backgroundColor: "#e34d3c",
                              borderRadius: "20px",
                              borderWidth: "0px",
                            }}
                            form="sib-form"
                            type="submit"
                          >
                            <svg
                              className="icon clickable__icon progress-indicator__icon sib-hide-loader-icon"
                              viewBox="0 0 512 512"
                            >
                              <path d="M460.116 373.846l-20.823-12.022c-5.541-3.199-7.54-10.159-4.663-15.874 30.137-59.886 28.343-131.652-5.386-189.946-33.641-58.394-94.896-95.833-161.827-99.676C261.028 55.961 256 50.751 256 44.352V20.309c0-6.904 5.808-12.337 12.703-11.982 83.556 4.306 160.163 50.864 202.11 123.677 42.063 72.696 44.079 162.316 6.031 236.832-3.14 6.148-10.75 8.461-16.728 5.01z" />
                            </svg>
                            ANMELDEN
                          </button>
                        </div>
                      </div>

                      <input
                        type="text"
                        name="email_address_check"
                        value=""
                        onChange={() => {}}
                        className="input--hidden"
                      />
                      <input
                        type="hidden"
                        name="locale"
                        value="de"
                        onChange={() => {}}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="lower-circle">
        <div className="partners-content">
          <h2>UNSERE PARTNER & UNTERSTÜTZER</h2>
          <p>
            Unser Projekt wird vom ehrenamtlichen Charakter getragen. Wir werben
            deshalb aktiv um Sponsoren und danken für eure Unterstützung.
          </p>
          <p>
            <Link reloadDocument to={{ pathname: "/partner" }} className="link">
              Jetzt Partner und Unterstützer werden
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Footer;
