import { ReactSVG } from "react-svg";
import { Link } from "react-router-dom";
import logo from "../../assets/Logo.svg";

function Logo() {
  return (
    <div className="logo-container">
      <Link reloadDocument to={{ pathname: "/" }}>
        <ReactSVG src={logo} />
      </Link>
    </div>
  );
}

export default Logo;
