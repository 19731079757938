import {
  Route,
  Routes,
  BrowserRouter as Router,
  useLocation,
} from "react-router-dom";
import "./css/App.scss";
import "./css/fonts.scss";
import Header from "./components/Header/Header";
import Home from "./components/Content/Home";
import Footer from "./components/Footer/Footer";
import Partner from "./components/Footer/Partner";
import { useEffect, useState } from "react";
import Team from "./components/Content/Team";
import Vision from "./components/Content/Vision";
import Tour from "./components/Content/Tour";
import TourRoute from "./components/Content/Route";
import Impressum from "./components/Content/Impressum";
import DataSecurity from "./components/Content/DataSecurity";
import Sponsors from "./components/Content/Sponsors";
import SponsorsOld from "./components/Content/Sponsors_old";
import Newsletter from "./components/Content/Newsletter";

function Main() {
  const location = useLocation();

  const [isHome, setIsHome] = useState(location.pathname === "/");
  const [isBlog, setIsBlog] = useState(
    location.pathname === "/team" || location.pathname === "/sponsoring"
  );
  const [isTour, setIsTour] = useState(location.pathname === "/tour");

  useEffect(() => {
    if (location.pathname === "/" && !isHome) {
      setIsHome(true);
    } else if (location.pathname !== "/" && isHome) {
      setIsHome(false);
    }

    if (
      (location.pathname === "/team" || location.pathname === "/sponsoring") &&
      !isBlog
    ) {
      setIsBlog(true);
    } else if (
      location.pathname !== "/team" &&
      location.pathname !== "/sponsoring" &&
      isBlog
    ) {
      setIsBlog(false);
    }

    if (location.pathname === "/tour" && !isTour) {
      setIsTour(true);
    } else if (location.pathname !== "/tour" && isTour) {
      setIsTour(false);
    }
  }, [isBlog, isHome, isTour, location, setIsHome, setIsBlog, setIsTour]);

  return (
    <div className="App">
      <header className="header">
        <Header />
      </header>
      <main
        className={`main${isHome ? " is-home" : ""}${isBlog ? " is-blog" : ""}${
          isTour ? " is-tour" : ""
        }`}
      >
        <div
          className={`route-container${isHome ? " is-home" : ""}${
            isBlog ? " is-blog" : ""
          }`}
        />
        <div className={`main-container`}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/mission" element={<Vision />} />
            <Route path="/route" element={<Tour />} />
            <Route path="/tour" element={<TourRoute />} />
            <Route path="/team" element={<Team />} />
            <Route path="/impressum" element={<Impressum />} />
            <Route path="/datenschutz" element={<DataSecurity />} />
            <Route path="/partner" element={<SponsorsOld />} />
            <Route path="/sponsoring" element={<Sponsors />} />
            <Route path="/newsletter" element={<Newsletter />} />
          </Routes>
        </div>
      </main>
      <footer id="newsletter" className="footer">
        <Footer />
      </footer>
      <Partner />
    </div>
  );
}

function App() {
  return (
    <Router>
      <Main />
    </Router>
  );
}

export default App;
