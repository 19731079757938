import { ReactSVG } from "react-svg";
import arrowRight from "../../assets/icon_arrow4_zinnoberrot.svg";
import arrowUp from "../../assets/icon_arrow_zinnoberrot.svg";
import arrowEtappen from "../../assets/icon_etappen_zinnoberrot.svg";
import arrowEtappenD from "../../assets/icon_etappen_zinnoberrot_d.svg";
import arrowLocation from "../../assets/icon_location_zinnoberrot.svg";
import arrowLocationD from "../../assets/icon_location_zinnoberrot_d.svg";
import arrowTeamD from "../../assets/icon_team_zinnoberrot_d.svg";
import logoRehaZentrum from "../../assets/logo_reha_zentrum.svg";
import logoMacoHc from "../../assets/logo_maco_hc.svg";
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

function Home() {
  const [scrollDone, setScrollDone] = useState(false);
  useEffect(() => {
    if (window?.location?.hash === "#newsletter" && !scrollDone) {
      setTimeout(() => {
        const element = document.getElementById("newsletter");
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "start" });
          setScrollDone(true);
        }
      }, 1000);
    }
  }, [scrollDone]);

  return (
    <div className="home-container">
      <div className="content-grid">
        <div className="content-left">
          <div className="content-left-title">
            <div className="content-left-maintitle">
              von<h1>WISMAR</h1>nach<h1>WIEN</h1>
            </div>
            <div className="content-left-subtitle">
              entlang der <b>DEMARKATIONSLINIE</b>
              <br />
              vom <b>Mai 1945</b>
            </div>
          </div>

          <div className="content-left-info">
            <div className="content-left-info-left">
              <ReactSVG src={arrowRight} />
              <span>ca. 1.400 km</span>
            </div>
            <div className="content-left-info-right">
              <ReactSVG src={arrowUp} />
              <span>ca. 9.500 hm</span>
            </div>
            <div className="content-left-info-left">
              <ReactSVG src={arrowEtappen} />
              <span>
                17 Etappen in 18 Tagen
                <br />
                zu den Vereinten Nationen
              </span>
            </div>
            <div className="content-left-info-right">
              <ReactSVG src={arrowLocation} />
              <span>80 Gedenkorte</span>
            </div>
          </div>
          <div className="content-left-text">
            <p>
              Am <b>27. Juni 2025</b> starten wir von der KinderUni Wismar
              unsere Fahrradtour zur UNO-City nach Wien anlässlich des Gedenkens
              an das Ende des Zweiten Weltkrieges und an die Gründung der
              Vereinten Nationen vor 80 Jahren.
            </p>
            <h3>Website-Launch am 27. März 2025</h3>
            <p>
              Hier findet ihr drei Monate vor dem Start alle Informationen zur
              Tour, den Gedenkorten und der Anmeldeoption für unsere erste
              Etappe.
            </p>
            <p>
              Um nichts zu verpassen, meldet euch bei unserem Newsletter an.
            </p>
          </div>
          <div className="content-left-button">
            <a href="#newsletter">
              <div className="icon">
                <ReactSVG src={arrowEtappenD} />
              </div>
              Zum Newsletter
            </a>
          </div>
        </div>
        <div className="content-right">
          <div className="content-right-top">
            <div className="content-right-content-title">
              <h2>Was ist die Demarkationslinie?</h2>
            </div>
            <div className="content-right-image">
              <div className="content-right-image-circle meeting-line" />
              <div className="content-right-content-text">
                <p>
                  An der <b>Demarkationslinie</b> – auch Elbe-Mulde-Linie
                  genannt – trafen im Mai 1945 die westlichen Alliierten auf die
                  Rote Armee. Am 8. Mai endete der Zweite Weltkrieg in Europa.
                </p>
                <div class="clearfix"></div>
                <p>
                  Der Handschlag von Torgau symbolisiert den Friedenswillen und
                  nur wenige Wochen später wurden die Vereinten Nationen
                  gegründet.
                </p>
              </div>
            </div>
            <div className="content-right-content">
              <div className="content-right-content-button">
                <Link reloadDocument to={{ pathname: "/route" }}>
                  <div className="icon">
                    <ReactSVG src={arrowEtappenD} />
                  </div>
                  Zur Route
                </Link>
              </div>
            </div>
          </div>
          <div className="content-right-middle">
            <div className="content-right-content-title">
              <h2>Was ist das Ziel?</h2>
            </div>
            <div className="content-right-image">
              <div className="content-right-image-circle mission" />
              <div className="content-right-content-text">
                <p>
                  Mit unserer <b>Gedenk-Radtour</b> entlang der
                  Demarkationslinie wollen wir an die Ereignisse vor mehr als 80
                  Jahren erinnern und ein Zeichen für Frieden in Europa und in
                  der Welt setzen.
                </p>
              </div>
            </div>
            <div className="content-right-content">
              <div className="content-right-content-button">
                <Link reloadDocument to={{ pathname: "/mission" }}>
                  <div className="icon">
                    <ReactSVG src={arrowLocationD} />
                  </div>
                  Zur Mission
                </Link>
              </div>
            </div>
          </div>
          <div className="content-right-bottom">
            <div className="content-right-content-title">
              <h2>Wer sind wir?</h2>
            </div>
            <div className="content-right-image">
              <div className="content-right-image-circle team" />
              <div className="content-right-content-text">
                <p>
                  Wir sind fünf radsport- und geschichtsbegeisterte
                  Familienväter. Wir stehen für{" "}
                  <b>Frieden und Völkerverständigung</b> und wollen mit unserem
                  Projekt einen bildungspolitischen Beitrag leisten.
                </p>
              </div>
            </div>
            <div className="content-right-content">
              <div className="content-right-content-button">
                <Link reloadDocument to={{ pathname: "/team" }}>
                  <div className="icon">
                    <ReactSVG src={arrowTeamD} />
                  </div>
                  Zum Team
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="main-sponsoring-container">
        <div className="main-sponsoring">
          <h2>Unser Sponsoring</h2>
          <p>
            Ermöglicht wird der Erfolg maßgeblich durch die Unterstützung
            unterschiedlichster Sponsoren.
            <br />
            <br />
            Durch Sponsoring wird die Organisation, die Durchführung und die
            Medienarbeit des Projekts auf ein professionelles Level gehoben und
            unsere Botschaft bekommt die Aufmerksamkeit, die sie verdient.
            <br />
            <br />
            Auf unserer <a href="/sponsoring">Sponsoring-Seite</a> erfährst du
            mehr, wie du uns als Sponsor unterstützen kannst.
          </p>
          <div className="main-sponsoring-element-container">
            <div className="main-sponsoring-element-title">
              <h2>Platin-Sponsor</h2>
            </div>
            <div className="main-sponsoring-element-image">
              <div className="ribbon">
                <a href="/sponsoring">
                  noch verfügbar ★ ★ ★ ★<br />
                  Jetzt Platin-Sponsor werden
                </a>
              </div>
              <div className="ribbon is-mobile">
                <a href="/sponsoring">
                  Werde
                  <br />
                  Platin-Sponsor
                </a>
              </div>
              <img
                src="https://res.cloudinary.com/dvyf1gffu/image/upload/v1732006999/Placeholder_800d3f747c.jpg"
                alt="Platin-Sponsor"
                className="logo"
              />
            </div>
          </div>
          <div className="main-sponsoring-element-container is-gold">
            <div className="main-sponsoring-element-title">
              <h2>Gold-Sponsoren</h2>
            </div>
            <div className="main-sponsoring-element-image-container">
              <div className="main-sponsoring-element-image">
                <div className="ribbon">
                  <a href="/sponsoring">
                    noch verfügbar ★ ★ ★<br />
                    Jetzt Gold-Sponsor werden
                  </a>
                </div>
                <div className="ribbon is-mobile is-gold">
                  <a href="/sponsoring">
                    Werde
                    <br />
                    Gold-Sponsor
                  </a>
                </div>
                <img
                  src="https://res.cloudinary.com/dvyf1gffu/image/upload/v1732006999/Placeholder_800d3f747c.jpg"
                  alt="Gold-Sponsor"
                  className="logo"
                />
              </div>
              <div className="main-sponsoring-element-image">
                <div className="ribbon">
                  <a href="/sponsoring">
                    noch verfügbar ★ ★ ★<br />
                    Jetzt Gold-Sponsor werden
                  </a>
                </div>
                <div className="ribbon is-mobile is-gold">
                  <a href="/sponsoring">
                    Werde
                    <br />
                    Gold-Sponsor
                  </a>
                </div>
                <img
                  src="https://res.cloudinary.com/dvyf1gffu/image/upload/v1732006999/Placeholder_800d3f747c.jpg"
                  alt="Gold-Sponsor"
                  className="logo"
                />
              </div>
              <div className="main-sponsoring-element-image">
                <div className="ribbon">
                  <a href="/sponsoring">
                    noch verfügbar ★ ★ ★<br />
                    Jetzt Gold-Sponsor werden
                  </a>
                </div>
                <div className="ribbon is-mobile is-gold">
                  <a href="/sponsoring">
                    Werde
                    <br />
                    Gold-Sponsor
                  </a>
                </div>
                <img
                  src="https://res.cloudinary.com/dvyf1gffu/image/upload/v1732006999/Placeholder_800d3f747c.jpg"
                  alt="Gold-Sponsor"
                  className="logo"
                />
              </div>
            </div>
          </div>
          <div className="main-sponsoring-element-container is-silver">
            <div className="main-sponsoring-element-title">
              <h2>Silber-Sponsoren</h2>
            </div>
            <div className="main-sponsoring-element-image-container">
              <div className="main-sponsoring-element-image is-active">
                <a
                  href={"https://www.radissonhotels.com/en-us/corporate"}
                  rel="noreferrer"
                  target="_blank"
                >
                  <img
                    src="https://res.cloudinary.com/dvyf1gffu/image/upload/v1710104026/RHG_LOGO_RGB_608e6b7aca.jpg"
                    alt="Silber-Sponsor"
                    className="logo"
                  />
                </a>
              </div>
              <div className="main-sponsoring-element-image is-active">
                <a
                  href={"https://reha-schwerin.de/"}
                  rel="noreferrer"
                  target="_blank"
                >
                  <ReactSVG src={logoRehaZentrum} />
                </a>
              </div>
              <div className="main-sponsoring-element-image is-active">
                <a
                  href={"https://homecompany-moebel.com/"}
                  rel="noreferrer"
                  target="_blank"
                >
                  <ReactSVG src={logoMacoHc} />
                </a>
              </div>
              <div className="main-sponsoring-element-image">
                <div className="ribbon">
                  <a href="/sponsoring">
                    noch verfügbar ★ ★<br />
                    Jetzt Silber-Sponsor werden
                  </a>
                </div>
                <div className="ribbon is-mobile is-silver">
                  <a href="/sponsoring">
                    Werde
                    <br />
                    Silber-Sponsor
                  </a>
                </div>
                <img
                  src="https://res.cloudinary.com/dvyf1gffu/image/upload/v1732006999/Placeholder_800d3f747c.jpg"
                  alt="Silber-Sponsor"
                  className="logo"
                />
              </div>
              <div className="main-sponsoring-element-image">
                <div className="ribbon">
                  <a href="/sponsoring">
                    noch verfügbar ★ ★<br />
                    Jetzt Silber-Sponsor werden
                  </a>
                </div>
                <div className="ribbon is-mobile is-silver">
                  <a href="/sponsoring">
                    Werde
                    <br />
                    Silber-Sponsor
                  </a>
                </div>
                <img
                  src="https://res.cloudinary.com/dvyf1gffu/image/upload/v1732006999/Placeholder_800d3f747c.jpg"
                  alt="Silber-Sponsor"
                  className="logo"
                />
              </div>
            </div>
          </div>
          {/* <h2 className="second">Weitere Unterstützer</h2>
          <p>
            Weiter Sponsoren, Partner und Unterstützer findest du auf unser{" "}
            <a href="/partner">Partnerseite</a>.
          </p> */}
        </div>
      </div>
    </div>
  );
}

export default Home;
