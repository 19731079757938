import { Link } from "react-router-dom";

function MenuItem(props) {
  return (
    <div className={`menu-item ${props.active ? "active" : ""}`.trim()}>
      <Link reloadDocument to={{ pathname: props.linkTo }}>
        {props.label}
      </Link>
    </div>
  );
}

export default MenuItem;
